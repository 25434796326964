import axios from 'axios';
// import NProgress from 'nprogress';
// import { toggle } from '@/common/Loading';

const instance = axios.create({
    // baseURL: process.env.BASE_URL_API,
    baseURL: 'https://api.xuxk.online/',//UAT
    // baseURL: 'http://165.232.174.106:19101',
    // baseURL: 'http://localhost:8009',
    headers: {
        'Content-Type': 'application/json',
    },
});

// instance.interceptors.request.use(config => {
//     NProgress.start();
//     return config
// });

// instance.interceptors.response.use(response => {
//     NProgress.done();
//     return response
// });

export default instance;
